import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Container } from 'react-bootstrap';

const SecondPage = () => (
  <Layout>
    <SEO title="Dr. Humankind Podcast" />
    <div class="subhead">
      <Container className="singleCol">
        <h1>Dr. Humankind Podcast</h1>
      </Container>
    </div>

    <Container className="singleCol">
        <p>Podcast coming soon</p>
        
      </Container>
  </Layout>
)

export default SecondPage
